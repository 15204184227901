/* ContactPage.css */

/* Styles for social media icons */
.social-media {
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  margin: 20px;
}

.social-media-icon {
  width: 40px; /* Maintain original size */
  height: 40px; /* Maintain original size */
  margin: 0 10px; /* Add margin to space out the icons */
}

/* Styles for post items */
.post-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
}

.post-item {
  position: relative; /* Positioning context for overlay */
  flex: 0 0 calc(30% - 20px); /* Adjust the width as needed */
  margin: 10px; /* Adjust the margin as needed */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items vertically */
  border: 3px solid #7278A0; /* Add black border */
  border-radius: 10px;
}

.post-item img {
  width: 100%; /* Ensure images fill their container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Add rounded corners to the image */
  transition: filter 0.3s; /* Add transition effect for hover */
}

.post-item:hover img {
  filter: brightness(80%); /* Darken the image on hover */
}

.instagram-link {
  position: absolute; /* Position the overlay */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none; /* Hide by default */
  justify-content: center;
  align-items: center;
}

.instagram-link:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background on hover */
}

.instagram-link img {
  width: 50px; /* Adjust size of Instagram logo */
}

.post-item:hover .instagram-link {
  display: flex; /* Show the overlay on hover */
}

#header {
  font-size: 30px;
}

/* Additional styling for text */
.text {
  font-size: 25px;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  .post-container {
    flex-direction: column; /* Display posts in a vertical column */
    align-items: center; /* Center items horizontally */
  }

  .post-item {
    flex: 0 0 100%; /* Make each post take up full width */
    margin: 10px 0; /* Add margin between posts */
  }
}
