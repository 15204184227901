/* global.css */
.page-container {
    padding: 10px 10px;
    margin: 0;
    text-align: center;
    max-width: 1500px; /* Adjust maximum width as needed */
    margin: 0 auto; /* Center horizontally */
    padding: 20px; /* Add padding for spacing */
    box-sizing: border-box; /* Include padding and border in the total width */
    
}

.title-text {
  font-size: 4em;
  margin-top: 50px;
}

body {
    background-color: beige;
}

@import url('fonts.css');

@font-face {
    font-family: 'Tangkiwood';
    src: url("./font.woff") format('woff'),
  }
  
  body{
    font-family: 'Tangkiwood', sans-serif; /* Set 'Tangkiwood' as the font family for the entire page */
  }
  
